.download-button {
    &:hover {
      background: #ffffff !important;
      color: #000000 !important;
      border: 1px solid #000000 !important;
    }
  }
  
  .cancel-button {
    &:hover {
      background: #000000 !important;
      border: #000000 !important;
      border-radius: 4px;
      color: #fbfbfb !important;
    }
  }
  