@import "abstracts/_variables.scss";

@import "../pages/Jobs/jobs";

@import "../widgets/Header/header";

@import "../pages/Jobs/CreateNewJob/createNewJob";

@import "../widgets/Accordian/Accordion";

@import "../pages/Jobs/CheckMatch/checkmatch";

@import "../widgets/ConfirmationModal/confirmationModal";

@import "../pages/Jobs/ViewResource/viewResource.scss";

@import "../pages/Jobs/DownloadJdPopup/downloadPopup";

@import "../widgets/ProgressBar/ProgressBar";

@import "../widgets/SideMenuBar/sideMenuBar";

@import "../widgets/Pagination/Pagination";

@import url("https://fonts.cdnfonts.com/css/nunito-sans");

@import "../widgets/EmailPreview/emailPreview";

@import "../widgets/MailFunctions/mailFunctions";

html,
body,
#root {
  min-height: 100vh;
  font-family: "Nunito Sans", sans-serif !important;
}
