.save-btn {
  &__onSave {
    width: 100%;
  }

  &:hover {
    background: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #000000 !important;
  }
}

.discard-btn {
  &:hover {
    background: #000000 !important;
    border: #000000 !important;
    border-radius: 4px;
    color: #fbfbfb !important;
  }
}
