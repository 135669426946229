.sideMenuBar {
  &__sidebar {
    height: 100vh;
    width: 100%;
    background-color: white;
  }
  &__header-toggle {
    width: 100%;
  }
  &__bg-menu-icons {
    background-color: #edebeb;
  }
  &__nav-logo-orange {
    color: #ff6600 !important;
  }
  &__nav-logo-name {
    font-family: "Segoe UI";
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #202124;
    &:hover {
      color: #ff6600;
      text-decoration: none;
    }
  }
  &__icon-size {
    font-size: 22px !important;
    cursor: pointer !important;
  }
  &__nav-logo-name-hide {
    display: none;
  }
  &__nav-link {
    text-decoration: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    align-items: center;
    &:hover {
      color: #ff6600;
      text-decoration: none;
    }
  }
  &__menubar {
    left: 0;
  }
  &__nav-logo-icon {
    font-size: 32px !important;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .sideMenuBar {
    &__space-toggle {
      padding-left: 1rem !important;
      width: 270px;
    }
    &__title-none {
      opacity: 0;
    }
    &__menubar {
      width: calc(3rem + 222px);
    }
  }
}

@media screen and (max-width: 760px) {
  .sideMenuBar {
    &__space-toggle {
      padding-left: 1rem !important;
      width: 270px;
    }
    &__title-none {
      opacity: 0;
    }
    &__menu-title {
      opacity: 0;
    }
    &__sidebar-hidden {
      opacity: 0;
    }
    &__header {
      position: fixed;
      top: 143px;
    }
  }
}
