.view-resource-data {
  color: #666666;
}

.btn:first-child:active {
  border-color: transparent !important;
}

.resource-table {
  border: 1px solid #d9d9d9 !important;
  width: 100%;
  border-radius: 50px;
  > thead {
    background-color: #616161;
    color: white;
    > tr {
      > th {
        width: auto;
      }
    }
  }
  > tbody {
    > tr {
      > td {
        padding: 9px;
      }
    }
  }
  &,
  & > tr,
  & > td {
    border: 0px solid #616161;
    font-size: 14px;
    font-weight: 400px;
  }
}
.sl_no_table {
  width: 75px !important;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
