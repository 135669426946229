.input__outline:focus {
  outline: none !important;
  border: none;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
  outline: none !important;
  border-color: transparent !important;
}
.ccHide {
  display: none !important;
}
.css-13cymwt-control {
  border: 1px solid #ececec !important;
}
.recipient {
  height: 35px;
}
.scroll-height {
  overflow-y: auto !important;
  max-height: 150px;
}
.error_text {
  color: red;
  font-size: 14px;
}
