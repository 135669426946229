.checkmatch-container {
  &__job-desc-background-color {
    background-color: #fafafa;
  }

  &__resource-table > thead {
    background-color: #616161;
    color: white;
    padding: 0.5rem 0.5rem !important;
  }
}
.checkmatch__descBg__desc {
  max-height: 150px;
  overflow-y: auto;
}
.hoverEffect {
  cursor: pointer;
}
.hoverEffect:hover {
  color: rgb(68, 68, 218);
}
.extraction-card {
  z-index: 9999;
  float: right;
  border-radius: 4px;
  background: var(--Blue, #0055d4);
  color: white;
}
.loading{
  font-size:100px;
  --duration:1s; 
  --num-dot:10; 
  --color-dot:white;
}
.loading > *{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  transform:rotate(calc((360deg / var(--num-dot)) * var(--index)));
}
.loading > *::before{
  position:absolute;
  content:'';
  top:.25em;
  left:.55em;
  width:.1em;
  height:.1em;
  transform:scale(0);
  border-radius:50%;
  background-color:var(--color-dot);
  animation:scaleTo1 var(--duration) ease-in-out calc(var(--duration) / var(--num-dot) * var(--index)) infinite reverse;
}
@keyframes scaleTo1 {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

.loading{
  width:1em;
  aspect-ratio:1;
  position:relative;
}
.loading::before{
  content:'';
  position:absolute;
  top:1px;
  left:1px;
  right:1px;
  bottom:1px;
  border-radius:.2em;
}

