.progress-bar {
    display: flex;
    height: 0.50rem!important;
    overflow: hidden;
    background:#D9D9D9!important;
    border-radius:100px!important;
  }
  .filler{
      background: linear-gradient(270deg, #4DFFDF 0%, #4DA1FF 96.35%);
      height: 0.90rem!important;
      border-radius:100px!important;
  }