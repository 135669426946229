.dropdown-toggle::after {
  display: none !important;
}

.template-radio-button-text {
  padding-left: 4%;
  font-weight: bold;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  accent-color: #000;
  border: 5px solid #000000;
  vertical-align: middle;
}

.jobs-page-container {
  &__create-new-btn {
    width: 100%;
    background: #000000 !important;
    border: #000000 !important;
    border-radius: 4px;
    height: 40px;

    &:hover {
      background: #ffffff !important;
      color: #000000 !important;
      border: 1px solid #000000 !important;
    }
  }

  &__table-border {
    border: 1px solid black !important;
  }

  &__table-width {
    min-width: 340px;
    max-width: 100vw;
    overflow-x: auto;
    &__flexscroll {
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888888;
      }
    }
  }
  &__td-min-width {
    min-width: 150px !important;
  }
  &__heading-background {
    background-color: #666666;
    color: #ffffff;
  }
  &__medium-modal {
    display: flex;
    justify-content: space-around;
    width: 500px !important;
  }
  &__modal-scrollable {
    max-height: 550px;
    overflow-y: auto;
    &__flexscroll {
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888888;
      }
    }
  }
}

.toast-container {
  position: fixed !important;
}
.modal-header .btn-close {
  background-color: transparent;
}
.modal-header {
  border-bottom: none;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  box-sizing: border-box;
  border: 5px solid #f3f3f3;
  border-top: 5px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.table {
  border: 1px solid #000 !important;
}
.table-header {
  background-color: #606060;
  border: transparent;
  color: #fff;
}
.table__data {
  border: transparent;
}
