.hire-assisto-pagination-container {
  font-size: var(--type-size-xs);
  background-color: var(--color-light-smoke) !important;
  box-shadow: var(--shadow-xs);
  &--border {
    border: 1px solid var(--border);
  }
  &-result {
    color: var(--color-smoke);
  }
  .hid-grid__column {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }
}
.hire-assisto-pagination {
  display: flex;
  justify-content: end;
  list-style: none;
  margin: 0;
  &__list {
    &-item,
    > button,
    .hire-assisto-popover__icon {
      display: block;
      background-color: transparent !important;

      padding: 7px 12px;
      min-height: 36px;
      min-width: 36px;
      border-radius: 0;
      line-height: 1;
      &:disabled {
        background: #ffffff !important;
        border: 1px solid rgba(158, 158, 158, 0.3);
        border-radius: 4px;
        cursor: none;
      }
      &:hover:not(span) {
        background: #999898 !important;
        color: black;
        border-radius: 4px;

        &:disabled {
            cursor: none;

          color: rgba(60, 60, 60, 0.32);
          background-color: transparent !important;
        }
      }
    }
    &:first-child button {
      background: #ffffff;
      border: 1px solid #9e9e9e;
      border-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      &:hover {
        background-color: #ffffff !important;
      }
    }
    &:last-child button {
      background: #ffffff;
      border: 1px solid #9e9e9e;
      border-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &:hover {
        background-color: #ffffff !important;
      }
    }
    &:not(:first-child) button {
      margin: 0 0 0 -1px;
    }
    &--active button {
      background: #666666 !important;
      border-radius: 4px;
      color: white;
      &:hover {
        background: #666666 !important;
        color: black;
      }
    }
    & > button {
      cursor: pointer;
    }
    & > span {
      color: #888888;
    }
  }
}
.nsp-assisto__theme--light button {
  border: none;
  background-color: none !important;
}
