.accordion-button {
  background: #ffffff;
  color: #000000 !important;
  border-top: 2rem;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;

  &:focus {
    border-color: #ffffff !important;
    box-shadow: none !important;
    border-bottom: 1px solid #ced4da !important;
  }

  &::after {
    background-image: url("../../assets/svgs/accordian-collapse-btn-down.svg") !important;
    margin-top: auto;
  }

  &:not(.collapsed) {
    color: #000000 !important;
    background-color: #ffffff !important;
  }
}

.accordion-item {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.accordion-body {
  padding: 0px 11px;
}

.border-acc {
  border-top: 1px solid #d9d9d9;
}

.form-control:focus {
  box-shadow: #ffffff;
  border-color: #9e9e9e;
}
