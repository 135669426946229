.breadcrumb-item {
  +.breadcrumb-item::before {
    content: ">" !important;
    align-self: baseline;
    color: #7d8daa;
  }
}

.createNewJob {
  &__plus-sign-button {
    font-size: 25px;
    cursor: pointer;
    color: rgba(168,0,0,1);  }

  &__minus-sign-button {
    font-size: 28px;
    cursor: pointer;
  }

  &__close-button {
    font-size: 20px;
    cursor: pointer;
  }
  &__color-red {
    color: red !important;
  }

  &__saveBtn {
    width: 100%;
    background: #000000 !important;
    border: #000000 !important;
    border-radius: 4px;
    height: 40px;

    &:hover {
      background: #ffffff !important;
      color: #000000 !important;
      border: 1px solid #000000 !important;
    }
  }

  &__CancelBtn {
    width: 100%;
    background: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #000000 !important;
    height: 40px;

    &:hover {
      background: #000000 !important;
      border: #000000 !important;
      border-radius: 4px;
      color: #ffffff !important;
    }

    &__validate-skill-list {
      background-color: #78a702 !important;
      color: #ffffff;
      border-radius: 4px;
    }
  }

  &-error-text {
    color: red;
  }
}

.validate-skill-list {
  background-color: #78a702;
  color: #ffffff;
  border-radius: 4px;
}

.form-control {
  &:focus {
    border-color: #000000 !important;
    box-shadow: 0 0 0 0.25rem #ffffff !important;
  }
}

.form-select {
  &:focus {
    border-color: #000000 !important;
    box-shadow: 0 0 0 0.25rem #ffffff !important;
  }
}

.breadcrumb-item {
  color: #000000 !important;

  a {
    color: #666666 !important;
    text-decoration: none !important;
  }
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  display: none !important;

}
