.search-btn {
    background: #ffffff;
    border: 1px solid #d9d9d9 !important;
    border-radius: 32px !important;
}

.nav-link:hover,
.nav-link:focus {
    color: var(--bs-nav-link-hover-color);
    text-decoration: underline solid black 3px;
}

.nav-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    position: sticky;
}

.sub-nav {
    height: 54px;
    background: #ebebeb;
}